import { track } from '@amplitude/analytics-react-native'
import { useNavigation } from '@react-navigation/native'
import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import 'firebase/compat/firestore'
import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Button, Snackbar, Subheading, Text, Title } from 'react-native-paper'
import { connect } from 'react-redux'
import ChallengeListCard from '../challenges/ChallengeListCard'
import ProfilePicture from '../media/ProfilePicture'
import OnDemandWorkoutCard from '../Ondemand/OnDemandWorkoutCard'
import Share from '../share/index'
import WorkoutListCard from '../workouts/WorkoutListCard'
import { RecordPageViewedAnalytics } from '../../services/analytics'
import { API_METHODS } from '../../constants/index'

function UserProfile(props) {
    const navigation = useNavigation();
    const [userWorkouts, setUserWorkouts] = useState([])
    const [usersChallenges, setUserChallenges] = useState([])
    const [usersOnDemandWorkouts, setUserOnDemandWorkouts] = useState([])
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        if (props.currentUser) {
            if (props.uid === firebase.auth().currentUser.uid) {
                generateWorkouts(props.workoutsUserFollowing, props.workouts)
                // refactor this? Redux store?
                generateOnDemandWorkouts(props.onDemandUserFollowing, props.onDemandWorkouts)
            } 
        }
    }, [props.challengesJoined, props.workouts, props.workoutsUserFollowing, props.onDemandWorkouts, props.onDemandWorkoutSaved, props.onDemandUserFollowing])


    useEffect(() => {
        if (props.currentUser) {
            RecordPageViewedAnalytics({
                "username": props.currentUser.name,
                "user_id": props?.uid,
                "page": "users-profile",
                "action": "viewed"
            })
            track(`track-user-profile-${props?.uid}`);
        }
    }, [props.currentUser])

    const generateWorkouts = (followingWorkouts, usersWorkouts) => {
        const concatedWorkouts = followingWorkouts.concat(usersWorkouts)
        setUserWorkouts(concatedWorkouts)
    }

    const generateOnDemandWorkouts = (followingOnDemandWorkouts, usersOnDemandWorkouts) => {
        const concatedWorkouts = followingOnDemandWorkouts.concat(usersOnDemandWorkouts)
        setUserOnDemandWorkouts(concatedWorkouts)
    }

    const generateChallenges = (followingChallenges, usersChallenges) => {
        const concatedChallenges = followingChallenges.concat(usersChallenges)
        setUserChallenges(concatedChallenges)
    }

    const onToggleSnackBar = () => setSnackbarVisible(!snackbarVisible);

    const onDismissSnackBar = () => setSnackbarVisible(false);

    const navigateWorkout = (id) => {
        navigation.navigate(
            'Content', {
                screen: 'Workout',
                params: {workoutId: id},
            }
        )
    }

    const navigateOnDemand = (id) => {
        navigation.navigate(
            'Content', {
                screen: 'OnDemandWorkout',
                params: {workoutId: id},
            }
        )
    }

    const navigateChallenge = (c) => {
        console.log("navigateChallenge: ", c)
        if (c.challenge_id) {
            navigation.navigate(
                'Content', {
                    screen: 'Challenge',
                    params: {challengeId: c.challenge_id},
                }
            )
        } else {
            navigation.navigate(
                'Content', {
                    screen: 'Challenge',
                    params: {challengeId: c.id},
                }
            )
        }
    }

    return (
        <View style={styles.container}>
            { props.currentUser ? (
                <View>
                    <View style={styles.profileDp}>
                        <View>
                            {
                                props.currentUser.profileImage ? (
                                    <ProfilePicture image={props.currentUser.profileImage} />
                                ): (
                                    <ProfilePicture image={null} />
                                )
                            }
                        </View>
                        <View style={styles.editBtns}>
                            <Button
                                mode="outlined"
                                icon="cog"
                                onPress={()=> 
                                    props.navigation.navigate('Edit Profile',
                                    {uid: props.uid}
                                )}
                            >
                                Edit Profile
                            </Button>
                            <Share
                                params={`/profile/${firebase.auth().currentUser.uid}`}
                                message={`${props.currentUser.name} on Move`}
                                title={`${props.currentUser.name} on Move`}
                                image={null}   
                            />
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Title>{props.currentUser.name}</Title>
                    </View>
                    {
                        props.currentUser.description && props.currentUser.description.length > 0 ? (
                            <View style={styles.section}>
                                <Subheading>{props.currentUser.description}</Subheading>
                            </View>

                        ): (
                            null
                        )
                    }
                    <ScrollView>
                        <View style={styles.section}>
                            <Button 
                                mode="contained"
                                icon="plus" 
                                onPress={()=> 
                                    props.navigation.navigate('Add')}
                            >
                                Create new
                            </Button>
                        </View>
                        <View style={styles.section}>
                            <Title style={styles.title}>My Challenges</Title>
                            {props.challengesJoined.length > 0 ? (
                                    <View>
                                        {
                                            props.challengesJoined.map((c, idx) => (
                                                <TouchableOpacity
                                                    onPress={() => navigateChallenge(c)}
                                                    style={styles.listCtn}
                                                    key={c.id}
                                                >
                                                    <ChallengeListCard challenge={c} />
                                                </TouchableOpacity>
                                                )
                                            )
                                        }
                                    </View>
                                ):(
                                    <View>
                                        <Text>No challenges yet. Create one!</Text>
                                        <Button 
                                            icon="plus"
                                            style={{marginTop: 30}}
                                            mode='contained'
                                            onPress={()=> 
                                                props.navigation.navigate('Add')}
                                        >
                                            Create challenge
                                        </Button>
                                    </View>
                            )}
                        </View>
                        <View style={styles.section}>
                            <Title style={styles.title}>My Workouts</Title>
                            {userWorkouts.length > 0 ? (
                                    <View>
                                        {
                                            userWorkouts.map((w, idx) => (
                                                <TouchableOpacity
                                                    onPress={() => navigateWorkout(w.id)}
                                                    style={styles.listCtn}
                                                    key={w.id}
                                                >
                                                    <WorkoutListCard workout={w} />
                                                </TouchableOpacity>
                                                )
                                            )
                                        }
                                    </View>
                                ):(
                                    <View>
                                        <Text>No workouts yet. Create one!</Text>
                                        <Button 
                                            icon="plus"
                                            style={{marginTop: 30}}
                                            mode='contained' 
                                            onPress={()=> 
                                                props.navigation.navigate('Add')}
                                        >
                                            Create workout
                                        </Button>
                                    </View>
                            )}
                        </View>
                        <View style={styles.section}>
                            <Title style={styles.title}>My OnDemand Workouts</Title>
                            {usersOnDemandWorkouts.length > 0 ? (
                                    <View>
                                        {
                                            usersOnDemandWorkouts.map((w, idx) => (
                                                <TouchableOpacity
                                                    onPress={() => navigateOnDemand(w.id)}
                                                    style={styles.listCtn}
                                                    key={w.id}
                                                >
                                                    <OnDemandWorkoutCard workout={w} />
                                                </TouchableOpacity>
                                                )
                                            )
                                        }
                                    </View>
                                ):(
                                    <View>
                                        <Text>No OnDemand workouts yet. Create one!</Text>
                                        <Button 
                                            icon="plus"
                                            style={{marginTop: 30}}
                                            mode='contained' 
                                            onPress={()=> 
                                                props.navigation.navigate('Add')}
                                        >
                                            Create workout
                                        </Button>
                                    </View>
                            )}
                        </View>
                    </ScrollView>
                </View>
            ): (
                null
            )}

            <Snackbar
                visible={snackbarVisible}
                onDismiss={onDismissSnackBar}
                action={{
                label: 'Undo',
                onPress: () => {
                    // Do something
                },
                }}>
                {snackbarMessage}
            </Snackbar>
        </View>
    )
}

const styles = StyleSheet.create({
    profileDp: {
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginBottom: 30

    },
    editBtns: {
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center',
    },
    section: {
        marginBottom: 30
    },
    title: {
        marginBottom: 15
    },
    listCtn:{
        marginBottom: 20
    },
})

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
    following: store.userState.following,
    workouts: store.userState.workouts,
    workoutsUserFollowing: store.userState.workoutsUserFollowing,
    challenges: store.userState.challenges,
    challengesJoined: store.userState.challengesJoined,
    subscriptions: store.userState.subscriptions,
    onDemandWorkouts: store.userState.onDemandWorkouts,
    onDemandWorkoutSaved: store.userState.ondemandWorkoutSaved,
    onDemandUserFollowing: store.userState.onDemandUserFollowing,
})

export default connect(mapStateToProps, null)(UserProfile);