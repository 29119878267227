import { ENVIRONMENT } from "@env";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Platform } from 'react-native';

export const RecordPageViewedAnalytics = (data) => {
    try {
        
        if (ENVIRONMENT !== 'production'){
            return;
        }

        const payload = {
            "path": "analytics",
            "http_method": "POST",
            "table": "move_analytics",
            "platform": Platform.OS,
            "username": data?.username ?? "",
            "user_id": data?.user_id ?? "",
            "page": data["page"],
            "action": data?.action ?? "",
            "page_owner_name": data?.page_owner_name ?? "",
            "page_owner_uid": data?.page_owner_uid ?? ""
        }

        return RecordAnalyticsTable(payload)

    } catch (error) {
        console.error("Failed RecordPageViewedAnalytics ", error)
    }
}

export const RecordWorkoutAnalytics = (data) => {
    try {
        
        if (ENVIRONMENT !== 'production'){
            return;
        }

        const date = new Date();
        const year = date.getFullYear();
        const month = date.toLocaleString('en-US', { month: 'long' }); // "March"

        const payload = {
            "path": "analytics",
            "http_method": "POST",
            "table": "move_workout_analytics",
            "platform": Platform.OS,
            "username": data["username"],
            "user_id": data["user_id"],
            "workout_name": data["workout_name"],
            "workout_id": data["workout_id"],
            "workout_type": data["workout_type"],
            "month": month,
            "year": year,
            "completed": data["completed"],
            "workout_owner_name": data?.workout_owner_name ?? "",
            "workout_owner_uid": data?.workout_owner_uid ?? "",
            "paid_workout": data?.paid_workout ?? ""
        }

        return RecordAnalyticsTable(payload)

    } catch (error) {
        console.error("Failed RecordPageViewedAnalytics ", error)
    }
}

export const RecordExerciseAnalytics = (data) => {
    try {
        
        if (ENVIRONMENT !== 'production'){
            return;
        }

        const payload = {
            "path": "analytics",
            "http_method": "POST",
            "table": "move_exercise_analytics",
            "workout_id": data["workout_id"],
            "exercise_name": data["exercise_name"],
            "user_id": data["exercise_name"],
            "username": data["username"],
            "sets": data?.sets ?? null,
            "reps": data?.reps ?? null,
            "repRest": data?.paid_workout ?? null,
            "weight": data?.repRest ?? null,
            "weightUOM": data?.weightUOM ?? "",
            "time": data?.time ?? "",
            "distance": data?.distance ?? null,
            "distanceUOM": data?.distanceUOM ?? ""
        }

        return RecordAnalyticsTable(payload)

    } catch (error) {
        console.error("Failed RecordExerciseAnalytics ", error)
    }
}

const RecordAnalyticsTable = async (data) => {
    try {
        const functions = getFunctions();
        const callRecordPb = httpsCallable(functions, 'api_gateway');
        const result = await callRecordPb(data)
        return result.data;
    } catch (error) {
        throw new Error(`[analytics-error] RecordAnalyticsTable:  ${error}`);
    }
}