import { useNavigation } from '@react-navigation/native';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, Image, ScrollView, StyleSheet, View } from 'react-native';
import { Button, IconButton, Modal, Paragraph, Portal, Provider, Subheading, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsersData } from '../../redux/actions';
import { RecordExerciseAnalytics, RecordWorkoutAnalytics } from '../../services/analytics';
import Media from '../media/index';
import SessionExercises from '../Session/SessionExercises';
import RestScreen from './RestScreen';

function Session(props) {
    const navigation = useNavigation();
    const [exercises, setExercises] = useState(null)
    const [challenge, setChallenge] = useState(null)
    const [challengeId, setChallengeId] = useState(null)
    const [workoutId, setWorkoutId] = useState(null)
    const [newChallenge, setNewChallenge] = useState(null)
    const [workout, setWorkout] = useState(null)
    const [exerciseSets, setExerciseSets] = useState(1)
    const [exerciseRest, setExerciseRest] = useState(false)
    const [step, setStep] = useState(0)
    const [sessionStep, setSessionStep] = useState(0)
    const [imageLoading, setImageLoading] = useState(true)
    const [complete, setComplete] = useState(false)
    const [visible, setVisible] = React.useState(false);
    const [weekIndex, setWeekIndex] = useState()
    const [dayIndex, setDayIndex] = useState()
    const [finishLoading, setFinishLoading] = useState(false)
    const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(0);
    const [sessionPbAnalyticsId, setSessionPbAnalyticsId] = useState(null)

    useEffect(() => {

        setExercises(props.route.params.exercises)
        if (props.route.params.challenge) {
            setChallenge(true)
            setChallengeId(props.route.params.challengeId)
            const findChallenge = props.challengesJoined.find( c => c.challenge_id === props.route.params.challengeId)
            setNewChallenge(findChallenge)
            setWeekIndex(props.route.params.weekIndex)
            setDayIndex(props.route.params.dayIndex)
        } else {
            setChallenge(false)
            setWeekIndex(props.route.params.weekIndex)
            setDayIndex(props.route.params.dayIndex)
            setWorkoutId(props.route.params.workoutId)
            const getWorkout = props.workouts.find( w => w.id === props.route.params.workoutId)
            setWorkout(getWorkout)
        }
    }, [])

    useEffect(() => {
        recordWorkoutSessionStart()
    }, [])

    const recordWorkoutSessionStart = async() => {
        try {
            const { challenge, challengeData, challengeId, workout, workoutId } = props.route.params
            let payload = {
                "username": props.currentUser?.name,
                "user_id": firebase.auth().currentUser.uid,
                "completed": false
            }

            if (workout) {
                // is regular workout
                payload["workout_name"] = workout?.name
                payload["workout_id"] = workoutId
                payload["workout_type"] = "workout"
                payload["workout_owner_name"] = workout?.by
                payload["workout_owner_uid"] = workout?.user_id
                payload["paid_workout"] = workout?.paid
                
            } else {
                // is a challenge
                payload["workout_name"] = challengeData?.name
                payload["workout_id"] = challengeId
                payload["workout_type"] = "challenge"
                payload["workout_owner_name"] = challengeData?.username
                payload["workout_owner_uid"] = challengeData?.user_id
                payload["paid_workout"] = challengeData?.paid
            }

            const response = await RecordWorkoutAnalytics(payload)
            setSessionPbAnalyticsId(response?.data?.id)
        } catch (error) {
            console.error(`Error recording workout session ${error}`)
        }
    }

    const recordExerciseAnalyticsFunc = () => {
        try {
            const { name, reps, sets, repsRest, weight, distance, time, weightUOM, distanceUOM } = exercises[step]

            RecordExerciseAnalytics({
                "workout_id": sessionPbAnalyticsId,
                "exercise_name": name,
                "user_id": firebase.auth().currentUser.uid,
                "username": props.currentUser?.name,
                "sets": isNaN(parseInt(sets)) ? null : parseInt(sets),
                "reps": isNaN(parseInt(reps)) ? null : parseInt(reps),
                "repRest": isNaN(parseInt(repsRest)) ? null : parseInt(repsRest),
                "weight": isNaN(parseInt(weight)) ? null : parseInt(weight),
                "weightUOM": weightUOM ?? "",
                "time": time ?? "",
                "distance": isNaN(parseInt(distance)) ? null : parseInt(distance),
                "distanceUOM": distanceUOM ?? ""
            })
            
        } catch (error) {
            console.error(`Error recording exercise session ${error}`)
        }
    }

    const imageLoaded = () => {
        setImageLoading(false)
    }

    const increaseStep = () => {
        const newStep = step + 1
        const setsAndRestsComplete = checkSetsAndRests()
        const allCompleted = exercises.every(exercise => exercise.completed);
        if (allCompleted) {
            if (setsAndRestsComplete) {
                recordExerciseAnalyticsFunc()
                if (challenge) {
                    completeChallenge()
                }
                setComplete(true)
                setSessionStep(2)
            }
        } else {
            // Sets
            if (setsAndRestsComplete) {
                recordExerciseAnalyticsFunc()
                // Set to next available step
                const nextAvailableExerciseIndex = findNextUncompletedExercise()
                updateSelectedExercise(nextAvailableExerciseIndex)
            }
        }
    }

    const findNextUncompletedExercise = () => {
        const nextIncompleteIndex = exercises.findIndex(exercise => !exercise.completed);
        return nextIncompleteIndex
    }

    const completeDay = () => {
        newChallenge.weeks[weekIndex].days[dayIndex].completed = true
        const checkWeekComplete = newChallenge.weeks[weekIndex].days.every(d => d.completed === true)
        if (checkWeekComplete) {
            newChallenge.weeks[weekIndex].completed = true
        }
        return newChallenge.weeks
    }

    const checkIfChallengeComplete = (weeks) => {
        const complete = weeks.every(x => x.days.every( a => a.completed === true))
        if (complete) {
            newChallenge.completed = true
        }
        return newChallenge.completed
    }

    const calcChallengeUnit = () => {
        const newNumDays = parseInt(newChallenge.complete_unit + 1)
        newChallenge.complete_unit = newNumDays
        return newChallenge.complete_unit 
    }

    const calcChallengePercent = (unit) => {
        const newPercent = parseInt(( unit / newChallenge.numDays) * 100 )
        newChallenge.complete_percent = newPercent
        return newChallenge.complete_percent
    }

    const updateLeaderboard = (unit, percent) => {
        firebase.firestore()
            .collection("challenges")
            .doc(challengeId)
            .collection("leaderboard")
            .doc(firebase.auth().currentUser.uid)
            .set({
                name: props.currentUser.name,
                score: unit,
                percent: percent,
                numDays: newChallenge.numDays,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            })
    }

    const updateChallengeDb = (updatedWeeks, challengeComplete, challengePercent, challengeUnit) => {
        firebase.firestore()
            .collection("userChallenges")
            .doc(firebase.auth().currentUser.uid)
            .collection("challenges")
            .doc(newChallenge.id)
            .update({
                complete_percent: challengePercent,
                complete_unit: challengeUnit,
                completed: challengeComplete,
                weeks: updatedWeeks
            })
            .then((res) => {
                setFinishLoading(false)
            })
            .catch((error) => {
            });

    }

    const completeChallenge = async () => {
        setFinishLoading(true)
        const updatedWeeks = await completeDay()
        const challengeComplete = await checkIfChallengeComplete(updatedWeeks)
        const challengeUnit = await calcChallengeUnit()
        const challengePercent = await calcChallengePercent(challengeUnit)
        const leaderboard = await updateLeaderboard(challengeUnit, challengePercent)
        const updateDb = await updateChallengeDb(updatedWeeks, challengeComplete, challengePercent, challengeUnit)
    }

    const decreaseStep = () => {
        setStep(step - 1)
    }

    const checkSetsAndRests = () => {
        // Yuck.
        if (exercises[step].sets) {
            if (parseInt(exercises[step].sets) == exerciseSets) {
                if (exercises[step].repsRest && exerciseRest) {
                    // Sets complete and Is currently in rest state
                    setExerciseSets(1)
                    setExerciseRest(false)
                    setSessionStep(0)
                    // Set Exercise complete to true
                    exercises[step].completed = true
                    return true
                } else if (exercises[step].repsRest && exerciseRest === false) {
                    // Go to exercise rest
                    setSessionStep(1)
                    setExerciseRest(true)
                    return false
                }
            } else if (exerciseSets < parseInt(exercises[step].sets)) {
                if (exercises[step].repsRest && exerciseRest) {
                    // Sets not complete and Is currently in rest state
                    // Go to next exercise set
                    setExerciseRest(false)
                    const newSet = exerciseSets + 1
                    setExerciseSets(newSet)
                    setSessionStep(0)
                    return false
                } else if (exercises[step].repsRest && exerciseRest === false) {
                    // Go to the exercise rest
                    setSessionStep(1)
                    setExerciseRest(true)
                    return false
                }
            } else {
            }
        } else {
            exercises[step].completed = true
            return true
        }
    }
    
    const pause = () => {
    }

    const handleStep = (operation) => {
        if (operation === 'add') {
            increaseStep()
        } else if (operation === 'sub') {
            decreaseStep()
        } 
    }

    const handleBack = () => {
        setComplete(false)
        setSessionStep(0)
    }

    const handleComplete = () => {
        if (challenge) {
            navigation.navigate('Content', {
                screen: 'Challenge',
                params: {
                    challengeId: challengeId,
                }
            })
        } else {
            navigation.navigate('Home', {
                screen: 'Main',
                params: {
                    screen: 'Profile',
                    params: {
                    uid: firebase.auth().currentUser.uid,
                    },
                },
            });
        }
    }

    const showModal = () => setVisible(true);

    const hideModal = () => setVisible(false);

    const updateSelectedExercise = (index) => {
        setSelectedExerciseIndex(index)
        setStep(index)
    }
    
    const containerStyle = {backgroundColor: 'white', padding: 20};

    return (
        <ScrollView>
            <View style={styles.container}>
                <View style={styles.contents}>
                    {
                        exercises !== null ? (
                            <View style={styles.contentCtn}>
                                {exercises !== null ? (
                                    {
                                        0: 
                                            <View style={styles.cardTopCtn}>
                                                <Subheading style={{fontWeight: "bold"}}>Exercise {step + 1} / {exercises.length}</Subheading>
                                                <View style={styles.cardCtn}>
                                                    <View style={styles.title}>
                                                        <Title>{exercises[step].name}</Title>
                                                        <View style={styles.titleInfo}>
                                                            { exercises[step].reps && <Subheading style={styles.explainerText}>(Set {exerciseSets} of {exercises[step].sets})</Subheading>}
                                                            { exercises[step].instructions.length > 0 &&   
                                                                <IconButton
                                                                    icon="information-outline"
                                                                    size={20}
                                                                    onPress={showModal}
                                                                    color="#008080"
                                                                />
                                                            }
                                                        </View>
                                                    </View>
                                                    <View style={styles.imgCtn}>
                                                        { exercises[step].image && exercises[step].image.url.length > 0 ? (
                                                            <View>
                                                                <Media contentType={exercises[step].image.metadata.type} url={exercises[step].image.url} component='cover' />  
                                                            </View>
                                                        ):(
                                                            <View>
                                                                <Image
                                                                    style={{ width: "100%", height: "auto", minHeight: 160, minWidth: 160 }}
                                                                    resizeMode="contain"
                                                                    source={require('../../assets/dumbell.png')}
                                                                /> 
                                                            </View>
                                                        )}
                                                    </View>
                                                    <View style={styles.infoCtn}>
                                                        { exercises[step].reps && <Subheading style={styles.explainerText}>{exercises[step].sets} sets x {exercises[step].reps} reps</Subheading>}
                                                        { exercises[step].weight && <Subheading style={styles.explainerText}>{exercises[step].weight} {exercises[step].weightUOM}</Subheading>}
                                                        { exercises[step].distance && <Subheading style={styles.explainerText}>{exercises[step].distance} {exercises[step].distanceUOM}</Subheading>}
                                                        { exercises[step].time && <Subheading style={styles.explainerText}>{exercises[step].time}</Subheading>}
                                                    </View>
                                                </View>
                                            </View>
                                            ,
                                        1: 
                                            <View style={styles.rest}>
                                                <View style={{justifyContent: 'center', alignItems: 'center', marginBottom: 20}}>
                                                    <Title>REST</Title>
                                                </View>
                                                <RestScreen next={handleStep} rest={exercises[step].repsRest} />
                                            </View>
                                            ,
                                        2:
                                            <View style={styles.complete}>
                                                <Title style={{color: "white"}}>Completed</Title>
                                                <Image
                                                    style={{ width: 100, height: 100 }}
                                                    source={require('../../assets/moveIcons/koala_victory_transparent_1.gif')}
                                                /> 
                                                <Button
                                                    style={{color: "#008080", backgroundColor: "white"}}
                                                    mode="outlined"
                                                    onPress={()=> handleBack()}
                                                >Back</Button>
                                                <Button
                                                    style={{color: "#008080", backgroundColor: "white"}}
                                                    mode="outlined"
                                                    onPress={()=> handleComplete()}
                                                    disabled={finishLoading}
                                                    loading={finishLoading}
                                                >Finish</Button>
                                            </View>
                                    }[sessionStep]
                                ):(
                                    null
                                )}
                                <View style={styles.buttonsCtn}>
                                    <IconButton 
                                        icon="rewind" 
                                        onPress={() => handleStep('sub')} 
                                        disabled={step === 0} 
                                        style={styles.iconButton}
                                        color="#008080"
                                        size={40} 
                                    />
                                    <IconButton 
                                        icon="pause" 
                                        onPress={() => console.log('Pressed')} 
                                        style={styles.iconButton}
                                        color="#008080"
                                        size={40}  
                                    />
                                    <IconButton 
                                        icon="fast-forward" 
                                        onPress={() => handleStep('add')}
                                        disabled={step === exercises.length || complete} 
                                        style={styles.iconButton}
                                        color="#008080"
                                        size={40}  
                                    />
                                </View>
                                <View style={styles.content}>
                                    <Title style={{ textAlign: 'center', marginBottom: 20, marginTop: 10}}>Exercises</Title>
                                    <SessionExercises 
                                        exercises={exercises}
                                        selectedExerciseIndex={selectedExerciseIndex} 
                                        setSelectedExerciseIndex={updateSelectedExercise}
                                        workoutId={workoutId}
                                        isChallenge={challenge}
                                        challengeId={newChallenge?.id}
                                        weekIndex={weekIndex}
                                        dayIndex={dayIndex}
                                        workout={workout}
                                        challenge={newChallenge}
                                    />
                                </View>
                                <Provider>
                                    <Portal>
                                        <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                                            <Title>Exercise Description</Title>
                                            <Paragraph>{exercises[step].instructions}</Paragraph>
                                        </Modal>
                                    </Portal>
                                </Provider>
                            </View>
                        ):(
                            <ActivityIndicator size="large" color="#008080" />
                    )}
                </View>
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center', 
        alignItems: 'center'
    },
    contents: {
        maxWidth: 600,
        width: "100%",
        padding: 20,
        maxHeight: "100%",
        height: "100%"
    },
    buttonsCtn: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    cardTopCtn: {

    },
    cardCtn: {
        marginTop: 20,
        minHeight: Dimensions.get('window').height * 0.65,
        justifyContent: 'space-around',
        backgroundColor: 'white',
        // alignItems: 'center',
        borderRadius: 10
    },
    contentCtn: {

    },
    complete: {
        backgroundColor: "#008080",
        flexDirection: 'column',
        justifyContent: 'space-around',
        minHeight: "75%",
        alignItems: 'center',
        minHeight: Dimensions.get('window').height * 0.65,
    },
    rest: {
        minHeight: Dimensions.get('window').height * 0.7,
        justifyContent: 'center'
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
    title: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20
    },
    titleInfo: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 5
    },
    infoCtn: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20
    }
})

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
    challengesJoined: store.userState.challengesJoined,
    workouts: store.userState.workouts,
})
const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUsersData }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(Session);
