import { Video } from 'expo-av';
import { useEffect, useRef } from 'react';
import { Dimensions, Image, StyleSheet } from 'react-native';
import { Card, Subheading } from 'react-native-paper';

export default function OnDemandCardMini({onDemandVideo}) {
    const video = useRef(null);

    useEffect(() => {
        return () => {
          video.current?.unloadAsync();
        };
      }, []);
    return (

        <Card style={[Dimensions.get('window').width > 800 ? styles.containerDesktop : styles.container]}>
            { onDemandVideo.playback_id && onDemandVideo.playback_id.length > 0 ? (
                // <View style={[Dimensions.get('window').width > 800 ? styles.imgDesktop : styles.img]}>
                    <Video
                        ref={video}
                        source={{uri: `https://stream.mux.com/${onDemandVideo.playback_id}.m3u8`,}}
                        useNativeControls
                        shouldPlay={false}
                        // resizeMode={Video.RESIZE_MODE_COVER}
                        resizeMode="cover"
                        style={[Dimensions.get('window').width > 800 ? styles.vidDesktop : styles.vid]}
                    />
                // </View>
            ):(
                // <View style={[Dimensions.get('window').width > 800 ? styles.defaultImgDesktop : styles.defaultImg]}>
                    <Image
                        source={require('../../assets/dumbell.png')}
                        style={{     
                            flex: 1,
                            height: undefined,
                            width: undefined
                        }}
                        resizeMode="contain"
                    />
                // </View>
            )}
            <Card.Content style={styles.content}>
                <Subheading style={styles.title}>{onDemandVideo.title}</Subheading>
            </Card.Content>
        </Card>
    )
}

const styles = StyleSheet.create({
    container: {
        margin: 10,
        width: 260,
        height: 260,
        backgroundColor: "white"
    },
    containerDesktop: {
        margin: 10,
        width: 280,
        height: 280,
        backgroundColor: "white"
    },
    content: {
        alignItems: 'center',
        alignContent: 'center'
    },
    title: {
        marginTop: 5,
        marginLeft: 5,
        fontWeight: "bold"
    },
    img: {
        width: '100%', 
        height: 120 
    },
    imgDesktop: {
        width: '100%', 
        height: 240 
    },
    vid: {
        width: '100%', 
        // height: 120 
        height: 150 
    },
    vidDesktop: {
        width: '100%', 
        height: 240 
        // height: 280 
    },
    defaultImg: {
        width: '100%', 
        height: 120,
        padding: 30 
    },
    defaultImgDesktop: {
        width: '100%', 
        height: 240,
        padding: 30 
    }
})