import * as Analytics from 'expo-firebase-analytics';
import { useEffect } from 'react';
import { Dimensions, Image, StyleSheet, View, ScrollView } from 'react-native';
import { Button, DataTable, Divider, Subheading, Text, TextInput, Title } from 'react-native-paper';
import CalculateEarning from './CalculateEarnings';
import { track } from '@amplitude/analytics-react-native'
import { RecordPageViewedAnalytics } from '../../services/analytics';

export default function SellFitnessContentOnline(props) {

    useEffect(() => {
        (async() => {
            await Analytics.logEvent('[Landing][SellFitnessContentOnline]: Accessed');
            track("track-marketing-sell-fitness-online");
        })();
    }, [])

    useEffect(() => {
        RecordPageViewedAnalytics({
            "page": "marketing-sell-content-online"
        })
    }, [])
    
    return (
        <ScrollView>
            <View style={styles.root}>
                <View style={styles.setCtnWidth}>
                    <View style={[Dimensions.get('window').width > 900 ? styles.sectionHeaderDesktop : styles.sectionHeaderDesktop]}>
                        <Title style={[Dimensions.get('window').width > 900 ? styles.headlineTitleDesktop : styles.headlineTitle]}>Easiest way to</Title>
                        <Title style={[Dimensions.get('window').width > 900 ? styles.headlineTitleDesktop : styles.headlineTitle]}>Sell workouts online</Title>
                        <Subheading style={[Dimensions.get('window').width > 900 ? styles.subheadingDesktop : styles.subheading]}>Create your own fitness style app in minutes. Collect monthly subscriptions</Subheading>
                        <View style={{width: '100%', marginBottom: 30}}>
                            <View style={{flexDirection: 'row', alignContent: 'center', justifyContent: 'center'}}>
                                <TextInput 
                                    style={{flex: 2}}
                                    placeholder="movefitness.io/ yourname"
                                    underlineColor='none'
                                />
                                <Button 
                                    style={{flex: 1, justifyContent: 'center'}}
                                    onPress={() => props.navigation.navigate('Home', {
                                        screen: 'Register',
                                    })}
                                    mode="contained"
                                >Claim</Button>
                            </View>
                        </View>
                        <Subheading style={[Dimensions.get('window').width > 900 ? styles.subheadingDesktop : styles.subheading]}>Free to use. Always.</Subheading>
                    </View>
                    <Divider style={styles.divider} />
                    <View style={[Dimensions.get('window').width > 900 ? styles.sectionIconsDesktop : styles.sectionCalc]}>
                        <CalculateEarning navigation={props.navigation} />
                    </View>
                    <Divider style={styles.divider} />
                    <View style={[Dimensions.get('window').width > 900 ? styles.sectionQuote1Desktop : styles.sectionQuote1]}>
                        <Subheading style={{fontStyle: 'italic'}}>"No more DMs, PDFs, Excel, Paypal, Cash App, Zoom links. I can just focus on uploading content and payments and distribution is taken care of. Easy for me and my clients love it."</Subheading>
                        <Subheading>Jane - Bay Pilates</Subheading>
                    </View>
                    <Divider style={styles.divider} />
                    <View style={[Dimensions.get('window').width > 900 ? styles.sectionIconDesktop : styles.sectionIcon]}>
                        <View style={[Dimensions.get('window').width > 900 ? styles.iconCtnDesktop : styles.iconCtn]}>
                            <View style={{}}>
                                <Image
                                    style={{ width: 80, height: 80 }}
                                    source={require('../../assets/landingIcons/money-flow.gif')}
                                /> 
                            </View>
                            <View style={{}}>
                                <Text style={{textAlign: 'center'}}>
                                    <Text style={{fontWeight: 'bold'}}>Get paid </Text>
                                    <Text>directly by your subscribers.</Text>
                                </Text>
                            </View>
                        </View>
                        <View style={[Dimensions.get('window').width > 900 ? styles.iconCtnDesktop : styles.iconCtn]}>
                            <View style={{}}>
                                <Image
                                    style={{ width: 80, height: 80 }}
                                    source={require('../../assets/landingIcons/video.gif')}
                                /> 
                            </View>
                            <View style={{}}>
                                <Text style={{textAlign: 'center'}}>
                                    <Text style={{fontWeight: 'bold'}}>Create </Text>
                                    <Text>Workouts, Challenges, On Demand videos </Text>
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={[Dimensions.get('window').width > 900 ? styles.sectionIconDesktop : styles.sectionIcon]}>
                        <View style={[Dimensions.get('window').width > 900 ? styles.iconCtnDesktop : styles.iconCtn]}>
                            <View style={{}}>
                                <Image
                                    style={{ width: 80, height: 80 }}
                                    source={require('../../assets/landingIcons/customer.gif')}
                                /> 
                            </View>
                            <View style={{}}>
                                <Text style={{textAlign: 'center'}}>
                                    <Text style={{fontWeight: 'bold'}}>Community </Text>
                                    <Text>building features</Text>
                                </Text>
                            </View>
                        </View>
                        <View style={[Dimensions.get('window').width > 900 ? styles.iconCtnDesktop : styles.iconCtn]}>
                            <View style={{}}>
                                <Image
                                    style={{ width: 80, height: 80 }}
                                    source={require('../../assets/landingIcons/speedometer.gif')}
                                /> 
                            </View>
                            <View style={{}}>
                                <Text style={{textAlign: 'center'}}>
                                    <Text style={{fontWeight: 'bold'}}>Easy. Instant. </Text>
                                    <Text>Have a Web, Apple & Android app in minutes</Text>
                                </Text>
                            </View>
                        </View>
                    </View>
                    <Divider style={styles.divider} />
                    <View style={[Dimensions.get('window').width > 900 ? styles.sectionHeaderDesktop : styles.sectionHeaderDesktop]}>
                        <Title>Move Compared</Title>
                    </View>
                    <View style={[Dimensions.get('window').width > 900 ? styles.sectionComparisonDesktop : styles.sectionComparison]}>
                        <DataTable>
                            <DataTable.Header>
                                <DataTable.Title></DataTable.Title>
                                <DataTable.Title>
                                    <Image
                                        style={{ width: 20, height: 20, marginBottom: 20 }}
                                        source={require('../../assets/moveIcons/move_icon_teal_bground.png')}
                                    /> 
                                    {/* <Text style={{fontWeight: 'bold'}}>Move</Text> */}
                                </DataTable.Title>
                                <DataTable.Title>
                                    <Image
                                        style={{ width: 20, height: 20, marginBottom: 20 }}
                                        source={require('../../assets/landingIcons/youtube.png')}
                                    /> 
                                    {/* <Text style={{fontWeight: 'bold'}}>YouTube</Text> */}
                                </DataTable.Title>
                                <DataTable.Title>
                                <Image
                                    style={{ width: 20, height: 20, marginBottom: 20 }}
                                    source={require('../../assets/landingIcons/pdf.png')}
                                /> 
                                {/* <Text style={{fontWeight: 'bold'}}>PDFs</Text> */}
                                </DataTable.Title>
                            </DataTable.Header>

                            <DataTable.Row>
                                <DataTable.Cell>App Apple & Android</DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row>
                                <DataTable.Cell>Website</DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row>
                                <DataTable.Cell>On demand video</DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row>
                                <DataTable.Cell>Playable workouts</DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row>
                                <DataTable.Cell>Challenges</DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row>
                                <DataTable.Cell>Subscriptions</DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row>
                                <DataTable.Cell>Leaderboards</DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/tick-white-background.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                                <DataTable.Cell>                            
                                    <Image
                                    style={{ width: 30, height: 30}}
                                    source={require('../../assets/landingIcons/cancel.png')}
                                /> </DataTable.Cell>
                            </DataTable.Row>
                        </DataTable>
                    </View>
                    <Divider style={styles.divider} />
                    <View style={[Dimensions.get('window').width > 900 ? styles.sectionHeaderDesktop : styles.sectionHeaderDesktop]}>
                        <View style={{marginTop: 50, marginBottom: 50}}>
                            <Button
                                mode="contained" 
                                onPress={() => props.navigation.navigate('Home', {
                                    screen: 'Register',
                                })}
                            >
                                Start selling with Move
                            </Button>
                        </View>
                    </View>
                </View>
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    root: {
        backgroundColor: 'white',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    setCtnWidth: {
        maxWidth: 800,
        width: "100%",
        padding: 20,
        
    },
    divider: {
        marginTop: 100,
        marginBottom: 100
    },
    sectionHeaderDesktop: {
        marginTop: 30,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headlineTitle: {
        fontSize: 30,
        marginBottom: 30,
    },
    headlineTitleDesktop: {
        fontSize: 50,
        marginBottom: 35,
    },
    subheading: {
        // fontSize: 30,
        marginBottom: 30,
    },
    subheadingDesktop: {
        // fontSize: 40,
        marginBottom: 30,
    },
    sectionHeader: {

    },
    sectionIconDesktop: {
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    sectionIcon: {
        flexDirection: 'column'
    },
    iconCtnDesktop: {
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        margin: 15,
        maxHeight: 200,
        maxWidth: 200
    },
    iconCtn: {
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        margin: 15,
    },
    sectionComparisonDesktop: {
        flexDirection: 'row',
        flexWrap: 'nowrap'
    },
    sectionComparison: {
        flexDirection: 'row',
        flexWrap: 'nowrap'
    },

})