import React, {useState, useEffect, useRef } from 'react'
import { View, StyleSheet, Dimensions  } from 'react-native'
import {  Button, } from 'react-native-paper'
import { Video, AVPlaybackStatus } from 'expo-av';

export default function video({url, component}) {
    const video = useRef(null);
    const { width } = Dimensions.get('window');
    const [status, setStatus] = useState({});
    const [type, setType] = useState(null);

    useEffect(() => {
        switch (component) {
            case 'cover':
                setType('cover')
                break;
            case 'exercise':
                setType('exercise')
                break;
            default:
                setType('default')
                break;
        }
    }, [])

    useEffect(() => {
        return () => {
          video.current?.unloadAsync();
        };
      }, []);


    return (
        <View style={styles.container}>
            {type === 'cover' && 
                <Video
                    ref={video}
                    source={{uri: url,}}
                    useNativeControls
                    resizeMode={Video.RESIZE_MODE_CONTAIN}
                    onPlaybackStatusUpdate={status => setStatus(() => status)}
                    shouldPlay={false} // Ensure it doesn't autoplay
                    // style={{ width: "100%", height: "auto", minHeight: 200, minWidth: 200 }}
                    // style={{ width: 360, height: 202 }}
                    style={{ maxWidth: Dimensions.get('window').width * 1, height: Dimensions.get('window').height * 0.3}}
                />
            }
            {type === 'exercise' && 
                <Video
                    ref={video}
                    source={{uri: url,}}
                    useNativeControls
                    resizeMode={Video.RESIZE_MODE_CONTAIN}
                    onPlaybackStatusUpdate={status => setStatus(() => status)}
                    style={{ width: 100, height: 100 }}
                    shouldPlay={false} // Ensure it doesn't autoplay
                />
            }
            {type === 'default' && 
                <Video
                    ref={video}
                    source={{uri: url,}}
                    useNativeControls
                    resizeMode={Video.RESIZE_MODE_CONTAIN}
                    onPlaybackStatusUpdate={status => setStatus(() => status)}
                    // style={{ width: "100%", height: "auto", minHeight: 200, minWidth: 200 }}
                    // style={{ width: 360, height: 202 }}
                    style={{ maxWidth: Dimensions.get('window').width * 1, height: Dimensions.get('window').height * 0.3}}
                    shouldPlay={false} // Ensure it doesn't autoplay

                />
            } 

            <View style={styles.buttons}>
                <Button
                    title={status.isPlaying ? 'Pause' : 'Play'}
                    onPress={() =>
                    status.isPlaying ? video.current.pauseAsync() : video.current.playAsync()
                    }
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {

    }
})
