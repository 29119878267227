import { track } from '@amplitude/analytics-react-native'
import { useNavigation } from '@react-navigation/native'
import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import 'firebase/compat/firestore'
import { useEffect, useState } from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Button, Snackbar, Subheading, Text, Title } from 'react-native-paper'
import { connect } from 'react-redux'
import { RecordPageViewedAnalytics } from '../../services/analytics'
import ChallengeListCard from '../challenges/ChallengeListCard'
import ProfilePicture from '../media/ProfilePicture'
import OnDemandWorkoutCard from '../Ondemand/OnDemandWorkoutCard'
import WorkoutListCard from '../workouts/WorkoutListCard'

function OtherUserProfile(props) {
    const navigation = useNavigation();
    const [user, setUser] = useState(null);
    const [userWorkouts, setUserWorkouts] = useState([])
    const [userOnDemandWorkouts, setUserOnDemandWorkouts] = useState([])
    const [usersChallenges, setUserChallenges] = useState([])
    const [usersOnDemandWorkouts, setUsersOnDemandWorkouts] = useState([])
    const [following, setFollowing] = useState(false)
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [subscribed, setSubscribed] = useState(false);

    const [currentProfileId, setCurrentProfileId] = useState(null);
    const [analyticsRecorded, setAnalyticsRecorded] = useState(false);

    // Effect to detect profile changes
    useEffect(() => {
        if (props.uid !== currentProfileId) {
        // Profile has changed
        setCurrentProfileId(props.uid);
        setUser(null); // Clear user data
        setAnalyticsRecorded(false); // Reset analytics flag
        //   getOtherUserData(); // Fetch new user data
        }
    }, [props.uid]);
  
    // Separate effect to record analytics once per profile view
    useEffect(() => {
        // Only run when we have user data and analytics haven't been recorded yet
        if (user && currentProfileId && !analyticsRecorded) {
            RecordPageViewedAnalytics({
                "username": props?.currentUser?.name,
                "user_id": firebase.auth()?.currentUser?.uid,
                "page_owner_uid": currentProfileId,
                "page_owner_name": user?.name,
                "page": "profile",
                "action": "viewed"
            });
            track(`track-user-profile-${currentProfileId}`);
            
            // Mark analytics as recorded for this profile
            setAnalyticsRecorded(true);
        }
    }, [user, currentProfileId, analyticsRecorded]);


    useEffect(() => {
        const { currentUser, workouts, challenges, following, subscriptions } = props;
        if (props.uid) {

            if(subscriptions.indexOf(props.uid) > -1 ){
                setSubscribed(true)
            } else {
                setSubscribed(false)
            }

            if(following.indexOf(props.uid) > -1 ){
                setFollowing(true)
            } else {
                setFollowing(false)
            }

            getOtherUserData()
        }
    }, [props.route, props.following, props.subscriptions, props.uid])

    const onToggleSnackBar = () => setSnackbarVisible(!snackbarVisible);

    const onDismissSnackBar = () => setSnackbarVisible(false);

    const onFollow = () => {
        const { currentUser  } = props;
        if (!currentUser) {
            navigation.navigate("Home", {
                screen: 'Login'
            })
        } else {
            firebase.firestore()
            .collection("following")
            .doc(firebase.auth().currentUser.uid)
            .collection("userFollowing")
            .doc(props.uid)
            .set({})
        }
    }

    const onUnfollow = () => {
        firebase.firestore()
        .collection("following")
        .doc(firebase.auth().currentUser.uid)
        .collection("userFollowing")
        .doc(props.uid)
        .delete()
    }

    const navigateWorkout = (id) => {
        props.navigation.navigate(
            'Content', {
                screen: 'Workout',
                params: {workoutId: id},
            }
        )
    }

    const navigateOnDemand = (id) => {
        props.navigation.navigate(
            'Content', {
                screen: 'OnDemandWorkout',
                params: {workoutId: id},
            }
        )
    }
    
    const navigateChallenge = (c) => {
        if (c.challenge_id) {
            props.navigation.navigate(
                'Content', {
                    screen: 'Challenge',
                    params: {challengeId: c.challenge_id},
                }
            )
        } else {
            props.navigation.navigate(
                'Content', {
                    screen: 'Challenge',
                    params: {challengeId: c.id},
                }
            )
        }
    }

    const navigateSubscribe = () => {
        if (!props.currentUser) {
            props.navigation.navigate("Home", {
                screen: 'Login'
            })            
        } else {
            props.navigation.navigate('Subscribe', {customer: firebase.auth().currentUser.uid, creator: user, creator_uid: props.uid})
        }
    }

    const getOtherUserData = () => {
        firebase.firestore()
        .collection("users")
        .doc(props.uid)
        .get()
        .then((snapshot) => {
            if (snapshot.exists) {
                const fetchedUserData = snapshot.data()
                setUser(fetchedUserData)
            }
            else {
                setSnackbarVisible(true)
                setSnackbarMessage("User does not exist")
            }
        })

        firebase.firestore()
            .collection("challenges")
            .where("user_id", "==", props.uid)
            .where("public", "==", true)
            .orderBy("creation", "desc")
            .get()
            .then((snapshot) => {
                let challengesData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                setUserChallenges(challengesData)
            })
        firebase.firestore()
            .collection("workouts")
            .where("user_id", "==", props.uid)
            .where("public", "==", true)
            .orderBy("creation", "desc")
            .get()
            .then((snapshot) => {
                let workoutData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                setUserWorkouts(workoutData)
            })
        firebase.firestore()
            .collection("onDemandWorkout")
            .where("user_id", "==", props.uid)
            .where("public", "==", true)
            .orderBy("creation", "desc")
            .get()
            .then((snapshot) => {
                let workoutData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                setUserOnDemandWorkouts(workoutData)
            })
    }

    return (
        <View style={styles.container}>
            {user !== null ? (
                <ScrollView>
                    <View style={styles.profileDp}>
                        <View>
                            {
                                user.profileImage ? (
                                    <ProfilePicture image={user.profileImage} />
                                ): (
                                    <ProfilePicture image={null} />
                                )
                            }
                        </View>
                        <View style={styles.editBtns}>
                            {following ? (
                                <Button
                                    onPress={() => onUnfollow()}
                                >Following</Button>
                            ) : (
                                <Button
                                    mode="contained" 
                                    onPress={() => onFollow()}
                                >Follow</Button>
                            )}
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Title>{user.name}</Title>
                    </View>
                    {
                        user.description && user.description.length > 0 ? (
                            <View style={styles.section}>
                                <Subheading>{user.description}</Subheading>
                            </View>

                        ): (
                            null
                        )
                    }
                    {
                        user.paid === true ? (
                            <View style={styles.section}>
                                {
                                    subscribed ? (
                                        <Button
                                            mode="outlined"
                                            disabled={true}
                                            onPress={() => navigateSubscribe()}
                                        >Unsubscribe</Button>
                                    ):(
                                        <Button
                                            mode="contained" 
                                            onPress={() => navigateSubscribe()}
                                        >Subscribe</Button>
                                    )
                                }
                            </View>

                        ):(
                            null
                        )
                    }

                    <View style={styles.section}>
                        <Title style={styles.title}>Challenges</Title>
                        {usersChallenges.length > 0 ? (
                                <View>
                                    {
                                        usersChallenges.map((c, idx) => (
                                            <TouchableOpacity
                                                onPress={() => navigateChallenge(c)}
                                                style={styles.listCtn}
                                                key={idx}
                                            >
                                                <ChallengeListCard challenge={c} />
                                            </TouchableOpacity>
                                            )
                                        )
                                    }
                                </View>
                            ):(
                                <View>
                                    <Text>{user.name} has no challenges yet</Text>
                                </View>
                        )}
                    </View>
                    <View style={styles.section}>
                        <Title style={styles.title}>Workouts</Title>
                        {userWorkouts.length > 0 ? (
                                <View>
                                    {
                                        userWorkouts.map((w, idx) => (
                                            <TouchableOpacity
                                                onPress={() => navigateWorkout(w.id)}
                                                style={styles.listCtn}
                                                key={idx}
                                            >
                                                <WorkoutListCard workout={w} />
                                            </TouchableOpacity>
                                            )
                                        )
                                    }
                                </View>
                            ):(
                                <View>
                                    <Text>{user.name} has no workouts yet</Text>
                                </View>
                        )}
                    </View>
                    <View style={styles.section}>
                        <Title style={styles.title}>My OnDemand Workouts</Title>
                        {userOnDemandWorkouts.length > 0 ? (
                                <View>
                                    {
                                        userOnDemandWorkouts.map((w, idx) => (
                                            <TouchableOpacity
                                                onPress={() => navigateOnDemand(w.id)}
                                                style={styles.listCtn}
                                                key={idx}
                                            >
                                                <OnDemandWorkoutCard workout={w} />
                                            </TouchableOpacity>
                                            )
                                        )
                                    }
                                </View>
                            ):(
                                <View>
                                    <Text>{user.name} has no ondemand workouts yet</Text>
                                </View>
                        )}
                    </View>
                </ScrollView>
            ):(
                <ActivityIndicator size="large" color="#008080" />
            )}
            <Snackbar
                visible={snackbarVisible}
                onDismiss={onDismissSnackBar}
                action={{
                label: 'Undo',
                onPress: () => {
                    // Do something
                },
                }}>
                {snackbarMessage}
            </Snackbar>
        </View>
    )
}

const styles = StyleSheet.create({
    profileDp: {
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginBottom: 30

    },
    editBtns: {
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center',
    },
    section: {
        marginBottom: 30
    },
    title: {
        marginBottom: 15
    },
    listCtn:{
        marginBottom: 20
    },
})

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
    following: store.userState.following,
    workouts: store.userState.workouts,
    workoutsUserFollowing: store.userState.workoutsUserFollowing,
    challenges: store.userState.challenges,
    challengesJoined: store.userState.challengesJoined,
    subscriptions: store.userState.subscriptions,
    onDemandWorkouts: store.userState.onDemandWorkouts
})

export default connect(mapStateToProps, null)(OtherUserProfile);